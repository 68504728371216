import * as React from 'react';
import dynamic from 'next/dynamic';
export { getStaticProps } from '@lcifr/pages/getStatic';
import { PageProps as LuciferPageProps, useRawPageConfig } from '@lcifr/pages';
import { ArticleProps } from 'templates/article';
import { JobProps } from 'templates/job';
import { PageProps } from 'templates/page';
import { ProductProps } from 'templates/product';
import { TechnologyProps } from 'templates/technology';

const templates = {
  article: dynamic<ArticleProps>(() =>
    import(`templates/article`).then((m) => m.default),
  ),
  job: dynamic<JobProps>(() => import(`templates/job`).then((m) => m.default)),
  page: dynamic<PageProps>(() =>
    import(`templates/page`).then((m) => m.default),
  ),
  product: dynamic<ProductProps>(() =>
    import(`templates/product`).then((m) => m.default),
  ),
  technology: dynamic<TechnologyProps>(() =>
    import(`templates/technology`).then((m) => m.default),
  ),
};

export default function Index({ data }: LuciferPageProps) {
  const pageConfig = useRawPageConfig();
  const pageTypeTemplate = (pageConfig as any)?.template?.name;
  const template =
    (data as any)?.pageDocument?.page?.template?.name ||
    pageTypeTemplate ||
    'page';

  const TemplateComponent = templates[template];

  return (
    <TemplateComponent
      // @ts-ignore
      pageData={data}
    />
  );
}
